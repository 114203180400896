body {
  font-family: 'ms_sans_serif' !;
  margin: 0;
  overflow-y: hidden;
}

.App {
  text-align: left;
  height: 100vh;

}

.loadingbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.stickyFooter {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100%;
}


.bts {
  position: absolute;
  z-index: -1;
}

.otherIcons {
  position: fixed;
  top: 20px;
  right: 20px;
}

.window-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-left: -1px;
  margin-top: -1px;
  transform: rotateZ(45deg);
  position: relative;
}

.projectModal {
  height: 80vh;
  transform: translate(-50%, -50%);
  position: fixed;
  left: 50%;
  top: 45%;
  z-index: 1050;
}

.window {
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
  position: fixed;
  margin-top: 15px;
  left: 50%;
  top: 50%;
  z-index: 1050;
}

.window-small {
  width: auto;
  min-height: 30vh !important;
  transform: translate(-50%, -50%);
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1050;
}

.snakeFont {
  font-size: 24px;
  font-weight: 600;
  background: -webkit-linear-gradient(rgb(215, 194, 8), rgb(5, 149, 61));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.window-game {
  min-width: auto;
  min-height: 30vh !important;
  transform: translate(-50%, -50%);
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 1050;
}





.window:nth-child(2) {
  margin: 2rem;
}

.footer {
  display: block;
  margin: 0.25rem;
  height: 31px;
  line-height: 31px;
  padding-left: 0.25rem;
}


/* PROJJECT SPECIFICS */

.pattern-dots {
  background-image: radial-gradient(currentColor 1.5px, transparent 1.5px) !important;
  background-size: calc(10 * 1px) calc(10 * 1px) !important;
}

.iconText {
  margin-top: -5;
}


.superfront {
  z-index: 2;
}

.desktopLogo {
  height: 55px;
  width: 80px;
}

.toolLogo {
  height: 50px;
}


.wordArt {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80%;

}

/* SNAKE STUFF */

.snakeScore {
  font-size: 20px;
  font-weight: 400;
  background: -webkit-linear-gradient(rgb(215, 194, 8), rgb(5, 149, 61));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.snake-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cutoutMax {
  height: 100%;
}

.cutoutTrailer {
  height: 50vh;
}

.snake-grid {
  width: 500px;
  height: 500px;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.grid-item {
  outline: 1px solid grey;
  width: 50px;
  height: 50px;
}

.snake {
  background: linear-gradient(90deg, #9ebd13 0%, #008552 100%);
  width: 50px;
  height: 50px;
}

.food {
  background: radial-gradient(circle, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
  border-radius: 50%;
  width: 50px;
  height: 50px;
}


/* MOBILE SCREEN */

@media only screen and (max-width: 750px) {
  .wordArt {
    height: 7vh;

  }

  .cutoutMax {
    height: 55vh;
  }

  .cutoutMin {
    height: 30vh;
  }

  .window-small {
    width: 90vw !important;
    height: auto !important;
    transform: translate(-50%, -50%);
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1050;
  }

  .window-game {
    width: 90vw !important;
    min-height: 30vh !important;
    transform: translate(-50%, -50%);
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: 1050;
  }

  .window {
    width: 90vw;
    height: 80vh;
    transform: translate(-50%, -50%);
    position: fixed;
    margin-top: 15px;
    left: 50%;
    top: 50%;
    z-index: 1050;
  }

  .snake-container {
    width: 70vw;
    height: 70vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .snake-grid {
    width: 70vw;
    height: 70vw;
    margin: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .grid-item {
    outline: 1px solid grey;
    width: 7vw;
    height: 7vw;
  }

  .snake {
    background: linear-gradient(90deg, #9ebd13 0%, #008552 100%);
    width: 7vw;
    height: 7vw;
  }

  .food {
    background: radial-gradient(circle, rgba(131, 58, 180, 1) 0%, rgba(253, 29, 29, 1) 50%, rgba(252, 176, 69, 1) 100%);
    width: 7vw;
    height: 7vw;
  }


}




/* DPAD  */

.circle {
  transform: translate(-50%, 0%);
  position: fixed;
  left: 50%;
  right: 50%;
  height: 125px;
  width: 125px;
  background-color: currentColor;
  position: absolute;
  border-radius: 100%;

}


.crossCenter {
  background-color: #333333;
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%);
  margin-top: 50%;
  margin-left: 50%;
  position: absolute;
}

.crossCenter>div:active {
  background: linear-gradient(90deg, #9ebd13 0%, #008552 100%);
  transform: translateY(4px);
}


.crossCircle {
  background-color: #292929;
  width: 25px;
  height: 25px;
  position: absolute;
  border-radius: 100%;
  margin-top: 5px;
  margin-left: 5px;
}

.crossTop {
  background-color: #333333;
  width: 35px;
  height: 35px;
  position: absolute;
  border-radius: 15%;
  margin-top: -30px;
}

.crossBottom {
  background-color: #333333;
  width: 35px;
  height: 35px;
  position: absolute;
  border-radius: 15%;
  margin-top: 30px;
}

.crossLeft {
  background-color: #333333;
  width: 35px;
  height: 35px;
  position: absolute;
  border-radius: 15%;
  margin-left: -30px;
}

.crossRight {
  background-color: #333333;
  width: 35px;
  height: 35px;
  position: absolute;
  border-radius: 15%;
  margin-left: 30px;
}
